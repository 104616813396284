.kiwi-unselectable * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
div.kiwi-messagelist-item.kiwi-messagelist-item--selected {
  border-left: 7px solid var(--brand-primary);
  transform: translateX(20px);
  transition: transform 0.1s;
}
div.kiwi-messagelist-item.kiwi-messagelist-item--selected .kiwi-messagelist-message {
  border-left-width: 0;
}
.kiwi-messagelist-item.kiwi-messagelist-item--selected .kiwi-messagelist-message *::selection {
  background-color: unset;
  color: unset;
}
.kiwi-unselectable .kiwi-messagelist-scrollback {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.kiwi-messagelist {
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  margin-bottom: 25px;
  position: relative;
}
.kiwi-messagelist--smoothscroll {
  scroll-behavior: smooth;
}
.kiwi-messagelist * {
  user-select: text;
}
.kiwi-messagelist::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}
.kiwi-messagelist::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
.kiwi-messagelist::-webkit-scrollbar-thumb {
  border-radius: 3px;
}
.kiwi-messagelist-item {
  /* Allow child elements to make use of margins+padding within messagelist items */
  overflow: hidden;
}
.kiwi-messagelist-message {
  padding: 0 10px;
  /* some message highlights add a left border so add a default invisble one in preperation */
  border-left: 3px solid transparent;
  overflow: hidden;
  line-height: 1.5em;
  margin: 0;
}
.kiwi-wrap--monospace .kiwi-messagelist-message {
  font-family: Consolas, monaco, monospace;
  font-size: 80%;
}
/* Remove the styling for none user messages, as they make the page look bloated */
.kiwi-messagelist-message-mode,
.kiwi-messagelist-message-traffic {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 0;
  line-height: normal;
  text-align: left;
}
/* Remove the min height from the message, as again, makes the page look bloated */
.kiwi-messagelist-body {
  min-height: 0;
  text-align: left;
  line-height: 1.5em;
  font-size: 1.05em;
  margin: 0;
  padding: 0;
}
/* Channel messages - e.g 'server on #testing22 ' message and such */
.kiwi-messagelist-message-mode,
.kiwi-messagelist-message-traffic,
.kiwi-messagelist-message-nick {
  margin: 10px 0;
  opacity: 0.85;
  text-align: center;
  border: none;
}
.kiwi-messagelist-message-mode:hover,
.kiwi-messagelist-message-traffic:hover,
.kiwi-messagelist-message-nick:hover {
  opacity: 1;
}
/* Absolute position the time on these messages so it's not above the message, it looks awful */
.kiwi-messagelist-message-mode .kiwi-messagelist-time,
.kiwi-messagelist-message-traffic .kiwi-messagelist-time {
  position: absolute;
  top: 1px;
  right: 10px;
}
.kiwi-messagelist-message--authorrepeat {
  border-top: none;
}
.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-nick,
.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-time {
  /* Setting the opacity instead visible:none ensures it's still selectable when copying text */
  opacity: 0;
  cursor: default;
}
.kiwi-container--sidebar-drawn .kiwi-messagelist::after {
  content: '';
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: fixed;
  pointer-events: none;
}
.kiwi-container--sidebar-drawn.kiwi-container--no-sidebar .kiwi-messagelist::after {
  width: 0;
  height: 0;
  display: none;
  pointer-events: inherit;
  position: static;
  z-index: 0;
}
.kiwi-messagelist-scrollback {
  text-align: center;
  padding: 5px;
}
.kiwi-messagelist-seperator + .kiwi-messagelist-message {
  border-top: none;
}
.kiwi-messagelist-message--blur {
  opacity: 0.3;
}
.kiwi-messagelist-nick {
  text-align: right;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: top;
  cursor: pointer;
  padding: 2px 4px;
  word-break: break-all;
}
.kiwi-messagelist-message-traffic .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-seperator {
  text-align: center;
  display: block;
  margin: 1em auto;
  position: sticky;
  top: -1px;
  z-index: 1;
}
.kiwi-messagelist-seperator > span {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0 1em;
  user-select: none;
}
/** Displaying an emoji in a message */
.kiwi-messagelist-emoji {
  width: 1.3em;
  display: inline-block;
  vertical-align: middle;
}
@keyframes emojiIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.kiwi-messagelist-emoji--single {
  animation: 0.1s ease-in-out 0s 1 emojiIn;
  font-size: 2em;
}
/** Message structure */
.kiwi-messagelist-body .kiwi-nick {
  cursor: pointer;
}
.kiwi-messagelist-nick:hover {
  overflow: visible;
  width: auto;
}
/* Topic changes */
.kiwi-messagelist-message-topic {
  border-radius: 5px;
  margin: 18px;
  margin-left: 0;
  padding: 5px;
  text-align: center;
  position: relative;
  min-height: 0;
  display: block;
}
.kiwi-messagelist-message-topic .kiwi-messagelist-body {
  min-height: 0;
  margin: 0;
}
.kiwi-messagelist-message-topic .kiwi-messagelist-body::before {
  display: none;
}
.kiwi-messagelist-message-topic.kiwi-messagelist-message-topic .kiwi-messagelist-time {
  display: none;
}
.kiwi-messagelist-message-topic.kiwi-messagelist-message-topic .kiwi-messagelist-nick {
  display: none;
}
/* Actions */
.kiwi-messagelist-message-action .kiwi-messagelist-message-body {
  font-style: italic;
}
/* Traffic (joins, parts, quits, kicks) */
.kiwi-messagelist-message-traffic.kiwi-messagelist-message-traffic .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-message-traffic .kiwi-messagelist-body {
  font-style: italic;
}
.kiwi-messagelist-message-action.kiwi-messagelist-message-action .kiwi-messagelist-nick {
  display: none;
}
/* MOTD */
.kiwi-messagelist-message-motd {
  font-family: monospace;
}
.kiwi-messagelist-message.kiwi-messagelist-message--hover,
.kiwi-messagelist-message.kiwi-messagelist-message--highlight,
.kiwi-messagelist-message.kiwi-messagelist-message-traffic--hover {
  position: relative;
}
/* Links */
.kiwi-messagelist-message-linkhandle {
  margin-left: 4px;
  font-size: 0.8em;
}
.kiwi-wrap--touch .kiwi-messagelist-message-linkhandle {
  display: none;
}
.kiwi-messagelist-joinloader {
  margin: 1em auto;
  width: 100px;
  /* the magic number below is the exact ratio of the kiwi logo height/width */
  height: calc 85.98726115px;
  overflow: hidden;
}
.kiwi-messagelist-joinloadertrans-enter,
.kiwi-messagelist-joinloadertrans-leave-to {
  height: 0;
  opacity: 0;
}
.kiwi-messagelist-joinloadertrans-enter-to,
.kiwi-messagelist-joinloadertrans-leave {
  height: 150px;
  opacity: 1;
}
.kiwi-messagelist-joinloadertrans-enter-active,
.kiwi-messagelist-joinloadertrans-leave-active {
  transition: height 0.5s, opacity 0.5s;
}
@media screen and (max-width: 700px) {
  .kiwi-messagelist-message,
  .kiwi-messageinfo {
    margin: 0;
  }
}
